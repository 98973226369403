<template>
  <button class="btn-app" @click="$emit.action">{{ btnText }}</button>
</template>

<script>
  export default {
    emits: ['action'],
    props: {
      btnText: String,
    }
  }
</script>

<style lang="scss">
  .btn-app {
    width: 100%;
    padding: 16px 12px;
    background: #DDFE56;
    border-radius: 50px;
    border: none;
    color: black;
    font: 800 20px/28px Avenir, sans-serif;
    cursor: pointer;
  }
</style>