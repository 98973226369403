<template>
    <div class="item-0" ref="backBlack"></div>

    <div id="header-main">

      <div class="header-item item-1" ref="headerDynamic">
        <img src="@/assets/logo.svg">
        <NavigationHead />
      </div>
      
      <div class="header-item item-2">
        <h1 class="header-h1">Appio Games</h1>
        <p class="header-p">More than games. It's a lifestyle. We make apps that not only entertain you or meet your daily needs but also enrich your life experience and personal growth. From quizzes to productivity and utility. </p>
      </div>
      
      <!-- <div class="header-item item-3">
        <div class="header-green-row">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="42" viewBox="0 0 24 42" fill="none">
            <path d="M12 1.5V40.5M12 40.5L22.5 29.8638M12 40.5L1.5 29.8638" stroke="#0D0D0D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div> -->
      
    </div>
  
</template>

<script setup>
import NavigationHead from './NavigationHead.vue';
import { watch, ref} from 'vue';

const backBlack = ref("");
const headerDynamic = ref("");

watch(backBlack.value.scrollHeight, headerDynamic.value.scrollHeight);

window.addEventListener('scroll', () => {
  if (window.scrollY >= backBlack.value.scrollHeight - headerDynamic.value.scrollHeight - 300) {
    headerDynamic.value.style.backgroundColor = 'black';
  }
  if (window.scrollY < 380) {
    headerDynamic.value.style.backgroundColor = 'transparent';
  }
})

</script>

<style lang="scss">
  @import '@/style/mixins.scss';

  .item-0 {
    width: 100%;
    position: absolute;
    top: 0;
    height: 75%;
    background: black;
    border-radius: 16px;
    transform: translateY(-20%) skewY(-205deg);
  }

  #header-main {
    width: 100%;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 66px;
    box-sizing: border-box;
    position: relative;
  
    .header-item {
      width: 100%;
    }
    
    .item-1 {
      @include flex-base-row;
      justify-content: flex-start;
      transition: all 1s ease
    }
    .item-2 {
      @include flex-base-column;
      gap: 13px;
    }
    .item-3 {
      @include flex-base-row;
      justify-content: flex-end;
    
      .header-green-row {
        width: 60px;
        height: 100px;
        padding: 20px 40px;
        @include flex-base-row;
        justify-content: center;
        background: #DDFE56;
        border-radius: 50%;
      }
    }
  }

  .header-h1 {
    font: 500 50px/55px Avenir, sans-serif;
  }
  .header-p {
    font: 500 19px/26.6px Avenir, sans-serif;
    text-align: justify;
  }

</style>