<template>
  <button class="apple" @click="$emit('action')">
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="31" viewBox="0 0 118 31" fill="none">
      <path d="M23.2386 19.9756C23.0485 20.4476 22.8689 20.9354 22.6577 21.4094C21.9659 22.9639 21.0074 24.3468 19.8747 25.6083C19.3902 26.147 18.8271 26.5925 18.136 26.8519C17.4032 27.1272 16.6652 27.0962 15.9325 26.8711C15.4262 26.716 14.9331 26.5179 14.4308 26.3476C13.7357 26.1113 13.0241 25.9674 12.2848 26.0077C11.6172 26.0521 10.9606 26.1995 10.3381 26.4447C9.75657 26.6645 9.17832 26.8889 8.56574 27.0163C7.63235 27.2097 6.81382 26.9615 6.0679 26.4057C5.30416 25.8367 4.7094 25.1079 4.13643 24.3594C3.34572 23.3371 2.68084 22.2234 2.15611 21.0424C1.55146 19.6561 1.10391 18.227 0.891352 16.7319C0.654374 15.0664 0.659654 13.4049 1.18774 11.7863C1.8122 9.87204 2.97003 8.38416 4.79654 7.46926C5.75674 6.97643 6.83054 6.74727 7.90828 6.80519C8.62119 6.84546 9.2945 7.06923 9.96451 7.30489C10.5177 7.49764 11.0755 7.67785 11.628 7.87324C12.0834 8.03497 12.5125 7.93331 12.9482 7.78479C13.7179 7.52075 14.4862 7.25208 15.2632 7.0118C16.1441 6.73141 17.0776 6.65644 17.9921 6.79265C18.9334 6.93457 19.8278 7.19333 20.6529 7.67323C21.3922 8.10348 22.0271 8.69181 22.5125 9.39611C20.4417 10.6503 19.3776 12.4557 19.5506 14.8935C19.7235 17.3312 21.0873 18.9241 23.2386 19.9756Z" fill="#0D0D0D"/>
      <path d="M17.5809 0.406832C17.5809 0.76923 17.608 1.12437 17.5763 1.47356C17.4647 2.69608 16.9327 3.74037 16.1505 4.66385C15.5801 5.33716 14.9213 5.91211 14.1173 6.29762C13.4704 6.61842 12.7485 6.75716 12.0288 6.69896C11.9711 6.33102 11.9722 5.95626 12.0321 5.58866C12.2149 4.27769 12.8242 3.174 13.7193 2.22212C14.3074 1.59964 15.0276 1.16001 15.8151 0.827319C16.3262 0.605258 16.8683 0.462783 17.4225 0.404851C17.4674 0.402871 17.5129 0.406832 17.5809 0.406832Z" fill="#0D0D0D"/>
      <path d="M29.8848 26.753L35.3636 12.4835H37.3981L43.238 26.753H41.0887L39.424 22.4313H33.4573L31.8895 26.753H29.8848ZM34.0018 20.8933H38.8398L37.3506 16.9392C36.8964 15.7405 36.5582 14.7549 36.336 13.9826C36.1511 14.9098 35.8939 15.8211 35.567 16.7082L34.0018 20.8933Z" fill="#0D0D0D"/>
      <path d="M44.5244 30.7149V16.4157H46.1205V17.759C46.4968 17.2335 46.9219 16.8394 47.3959 16.5767C47.8698 16.314 48.4441 16.182 49.1188 16.1807C50.0011 16.1807 50.7798 16.408 51.4549 16.8626C52.1299 17.3171 52.6393 17.9588 52.983 18.7874C53.3336 19.6505 53.5089 20.5748 53.4986 21.5064C53.4986 22.5626 53.3089 23.5149 52.9295 24.3633C52.5502 25.2118 51.9986 25.8616 51.2747 26.3126C50.5939 26.7513 49.8019 26.9861 48.992 26.9892C48.4453 26.9984 47.905 26.8713 47.4196 26.6196C46.9777 26.3908 46.5883 26.0725 46.2763 25.6849V30.7175L44.5244 30.7149ZM46.1087 21.643C46.1087 22.9734 46.378 23.9565 46.9166 24.5924C47.4553 25.2283 48.109 25.5462 48.8778 25.5462C49.6567 25.5462 50.3234 25.2162 50.8779 24.5561C51.4324 23.896 51.7099 22.8757 51.7103 21.4952C51.7103 20.1776 51.4395 19.1912 50.8977 18.5359C50.356 17.8807 49.7087 17.553 48.9557 17.553C48.2076 17.553 47.5475 17.9018 46.9754 18.5993C46.4033 19.2968 46.1151 20.3114 46.1107 21.643H46.1087Z" fill="#0D0D0D"/>
      <path d="M55.6113 30.7149V16.4157H57.2075V17.759C57.5842 17.2335 58.0093 16.8394 58.4828 16.5767C58.9563 16.314 59.5306 16.182 60.2057 16.1807C61.0884 16.1807 61.8672 16.408 62.5418 16.8626C63.2164 17.3171 63.7258 17.9588 64.0699 18.7874C64.4209 19.6509 64.5964 20.5757 64.5861 21.5077C64.5861 22.5639 64.3962 23.5162 64.0165 24.3647C63.6367 25.2131 63.085 25.8624 62.3616 26.3126C61.6811 26.7513 60.8892 26.9861 60.0796 26.9892C59.5329 26.9984 58.9926 26.8713 58.5072 26.6196C58.0651 26.3909 57.6755 26.0725 57.3632 25.6849V30.7175L55.6113 30.7149ZM57.1956 21.643C57.1956 22.9734 57.4649 23.9565 58.0036 24.5924C58.5422 25.2283 59.1944 25.5462 59.9601 25.5462C60.7382 25.5462 61.4049 25.2162 61.9602 24.5561C62.5156 23.896 62.7931 22.8757 62.7926 21.4952C62.7926 20.1776 62.5215 19.1912 61.9794 18.5359C61.4372 17.8807 60.7901 17.553 60.038 17.553C59.2899 17.553 58.6298 17.9018 58.0577 18.5993C57.4856 19.2968 57.1982 20.3114 57.1956 21.643Z" fill="#0D0D0D"/>
      <path d="M71.8184 22.1685L73.6006 22.0127C73.6843 22.7269 73.8805 23.3127 74.1895 23.7699C74.4984 24.2271 74.977 24.597 75.6252 24.8795C76.2738 25.1612 77.0037 25.3022 77.8148 25.3027C78.5356 25.3027 79.1717 25.1957 79.7231 24.9818C80.2745 24.768 80.6849 24.4742 80.9542 24.1006C81.2158 23.7475 81.3566 23.3195 81.3556 22.8801C81.3635 22.4561 81.2261 22.0421 80.9661 21.7071C80.7069 21.3722 80.2787 21.0914 79.6815 20.8648C79.2987 20.7156 78.4518 20.4837 77.1408 20.169C75.8298 19.8544 74.9116 19.5575 74.3862 19.2785C73.7049 18.9212 73.1971 18.4783 72.8626 17.9497C72.5279 17.4183 72.3539 16.8014 72.3616 16.1734C72.361 15.4541 72.5745 14.751 72.9749 14.1535C73.3837 13.5268 73.9806 13.0515 74.7657 12.7276C75.5508 12.4037 76.4237 12.2414 77.3844 12.2405C78.4405 12.2405 79.3733 12.4108 80.1826 12.7514C80.9918 13.092 81.6132 13.5935 82.0467 14.2558C82.4844 14.9257 82.7271 15.7042 82.7477 16.5041L80.9371 16.6401C80.8394 15.7384 80.5093 15.0563 79.9469 14.5938C79.3845 14.1312 78.5569 13.9009 77.4642 13.9026C76.3201 13.9026 75.4877 14.1119 74.9671 14.5304C74.4465 14.9489 74.1853 15.4534 74.1835 16.044C74.1771 16.2828 74.2235 16.5201 74.3195 16.7389C74.4154 16.9576 74.5586 17.1525 74.7387 17.3094C75.1017 17.6399 76.0507 17.979 77.5857 18.3267C79.1207 18.6743 80.1735 18.9777 80.7443 19.2369C81.5747 19.6198 82.188 20.1039 82.584 20.6892C82.9801 21.2745 83.1781 21.9511 83.1781 22.719C83.1768 23.4828 82.9501 24.2293 82.5266 24.865C82.0918 25.537 81.4671 26.0594 80.6526 26.4321C79.838 26.8048 78.9213 26.9914 77.9026 26.9919C76.6114 26.9919 75.5293 26.8037 74.6562 26.4275C73.8173 26.0792 73.1021 25.4876 72.6026 24.729C72.1045 23.9667 71.8326 23.0789 71.8184 22.1685Z" fill="#0D0D0D"/>
      <path d="M89.3584 25.1859L89.6119 26.7338C89.177 26.8309 88.7333 26.8831 88.2877 26.8896C87.6523 26.8896 87.1592 26.7891 86.8084 26.5879C86.4815 26.4122 86.2215 26.1339 86.0684 25.7958C85.9259 25.468 85.8546 24.7784 85.8546 23.727V17.7788H84.5693V16.4157H85.8546V13.8558L87.5966 12.8049V16.4157H89.3584V17.7788H87.5966V23.8234C87.5966 24.3229 87.6274 24.6442 87.689 24.7872C87.7509 24.9298 87.8566 25.0491 87.9907 25.1278C88.1306 25.2123 88.3302 25.2545 88.5894 25.2545C88.847 25.2491 89.1039 25.2261 89.3584 25.1859Z" fill="#0D0D0D"/>
      <path d="M90.4189 21.5843C90.4189 19.67 90.9512 18.2521 92.0157 17.3306C92.9047 16.5649 93.9884 16.182 95.2668 16.182C96.6882 16.182 97.8498 16.6476 98.7515 17.5788C99.6532 18.51 100.104 19.7965 100.105 21.4384C100.105 22.7683 99.9051 23.8148 99.506 24.5779C99.1158 25.331 98.5087 25.9497 97.7633 26.3542C96.9994 26.7763 96.1395 26.9941 95.2668 26.9866C93.8198 26.9866 92.6501 26.5228 91.7576 25.5951C90.8652 24.6674 90.4189 23.3305 90.4189 21.5843ZM92.2197 21.5843C92.2197 22.9085 92.5086 23.8997 93.0864 24.5581C93.3528 24.8751 93.6866 25.1285 94.0635 25.2998C94.4404 25.4711 94.8508 25.556 95.2648 25.5482C96.1344 25.5482 96.8578 25.2182 97.4352 24.5581C98.0126 23.898 98.3015 22.8889 98.3019 21.5308C98.3019 20.252 98.0115 19.2838 97.4306 18.6264C96.8497 17.9689 96.1278 17.6389 95.2648 17.6362C94.8515 17.6279 94.4415 17.7119 94.0647 17.8819C93.6879 18.0519 93.3537 18.3037 93.0864 18.6191C92.5091 19.2722 92.2201 20.2606 92.2197 21.5843Z" fill="#0D0D0D"/>
      <path d="M102.139 26.753V16.4157H103.716V17.9828C104.118 17.2501 104.49 16.766 104.831 16.5306C105.159 16.3007 105.551 16.1783 105.953 16.1801C106.543 16.1801 107.143 16.3684 107.754 16.7451L107.15 18.3703C106.764 18.1296 106.32 17.9984 105.865 17.9907C105.492 17.9875 105.129 18.1093 104.833 18.3366C104.52 18.5791 104.292 18.9147 104.181 19.2951C103.98 19.9585 103.882 20.6485 103.889 21.3414V26.7543L102.139 26.753Z" fill="#0D0D0D"/>
      <path d="M115.874 23.424L117.684 23.6478C117.399 24.704 116.871 25.5249 116.1 26.1107C115.33 26.6964 114.343 26.9884 113.141 26.9866C111.629 26.9866 110.43 26.521 109.545 25.5898C108.659 24.6586 108.216 23.3527 108.216 21.6721C108.216 19.9329 108.664 18.5833 109.559 17.623C110.455 16.6628 111.615 16.1824 113.041 16.182C114.423 16.182 115.552 16.6527 116.428 17.594C117.303 18.5353 117.741 19.859 117.742 21.5652C117.742 21.6686 117.739 21.8244 117.732 22.0325H110.024C110.089 23.1679 110.41 24.0375 110.987 24.6413C111.564 25.245 112.285 25.5467 113.148 25.5463C113.79 25.5463 114.339 25.3775 114.793 25.04C115.248 24.7024 115.608 24.1638 115.874 23.424ZM110.121 20.5915H115.893C115.815 19.7224 115.595 19.0702 115.233 18.635C114.674 17.9603 113.951 17.623 113.062 17.623C112.257 17.623 111.58 17.8923 111.032 18.431C110.485 18.9696 110.181 19.6898 110.121 20.5915Z" fill="#0D0D0D"/>
      <path d="M29.8848 10.5236V2.61487H32.6097C33.2258 2.61487 33.6951 2.65271 34.0177 2.72841C34.4447 2.81979 34.8429 3.01423 35.1775 3.29478C35.6015 3.65669 35.9243 4.12253 36.1142 4.64667C36.321 5.19544 36.4244 5.82232 36.4244 6.52732C36.4325 7.067 36.3615 7.60496 36.2139 8.12411C36.0992 8.53466 35.9174 8.92342 35.6759 9.27468C35.4807 9.55504 35.2368 9.79808 34.9557 9.99221C34.6668 10.1787 34.3472 10.3128 34.0117 10.3883C33.5949 10.4824 33.1683 10.5267 32.741 10.5203L29.8848 10.5236ZM30.9317 9.59021H32.6202C33.1417 9.59021 33.551 9.5418 33.848 9.44498C34.1134 9.36594 34.3564 9.22525 34.557 9.0344C34.8052 8.7862 34.9986 8.45262 35.1372 8.03368C35.2759 7.61473 35.3454 7.10667 35.3458 6.50949C35.3458 5.68216 35.2101 5.04626 34.9385 4.60179C34.667 4.15732 34.337 3.85983 33.9484 3.70932C33.6681 3.60107 33.2165 3.54694 32.5938 3.54694H30.9317V9.59021Z" fill="#0D0D0D"/>
      <path d="M37.3789 7.65872C37.3789 6.59815 37.674 5.81218 38.2641 5.30082C38.757 4.87615 39.3577 4.66382 40.0662 4.66382C40.8544 4.66382 41.4982 4.92192 41.9977 5.43812C42.4971 5.95433 42.7473 6.66746 42.7482 7.57753C42.7482 8.31552 42.6382 8.89576 42.4182 9.31823C42.202 9.73553 41.8656 10.0784 41.4524 10.3024C41.0284 10.5372 40.5508 10.6581 40.0662 10.6536C39.2644 10.6536 38.6159 10.3964 38.1209 9.88196C37.6258 9.36751 37.3785 8.62644 37.3789 7.65872ZM38.3776 7.65872C38.3776 8.39276 38.5376 8.94285 38.8575 9.30898C39.0076 9.48143 39.1929 9.61968 39.401 9.71442C39.609 9.80916 39.835 9.85818 40.0636 9.85818C40.2922 9.85818 40.5181 9.80916 40.7261 9.71442C40.9342 9.61968 41.1195 9.48143 41.2696 9.30898C41.5891 8.93889 41.7488 8.37801 41.7488 7.62637C41.7488 6.91742 41.588 6.38053 41.2663 6.01572C41.116 5.84401 40.9308 5.7064 40.723 5.61212C40.5153 5.51784 40.2897 5.46906 40.0616 5.46906C39.8334 5.46906 39.6079 5.51784 39.4001 5.61212C39.1923 5.7064 39.0071 5.84401 38.8569 6.01572C38.5374 6.37701 38.3776 6.92468 38.3776 7.65872Z" fill="#0D0D0D"/>
      <path d="M44.9437 10.5236L43.1904 4.79321H44.1911L45.1034 8.10034L45.4453 9.33144C45.4594 9.26983 45.5582 8.87597 45.7417 8.14985L46.6547 4.79321H47.6527L48.5109 8.11685L48.7967 9.21592L49.1268 8.11024L50.1077 4.79321H51.0516L49.2608 10.5229H48.2515L47.3399 7.09038L47.1187 6.11342L45.9583 10.5216L44.9437 10.5236Z" fill="#0D0D0D"/>
      <path d="M51.8672 10.5236V4.79318H52.7412V5.60775C53.1619 4.97669 53.7698 4.66116 54.565 4.66116C54.8917 4.65747 55.2157 4.72083 55.5169 4.84731C55.7745 4.94706 55.9996 5.11612 56.1671 5.33579C56.317 5.55117 56.42 5.79562 56.4694 6.05333C56.5055 6.23376 56.5236 6.54863 56.5236 6.99794V10.5209H55.5519V7.03754C55.5519 6.64148 55.5143 6.34553 55.439 6.1497C55.362 5.95229 55.2202 5.78683 55.037 5.68037C54.831 5.56009 54.5955 5.49973 54.3571 5.5061C53.963 5.49954 53.5808 5.64085 53.2858 5.90216C52.9856 6.1662 52.8356 6.66458 52.8356 7.3973V10.5262L51.8672 10.5236Z" fill="#0D0D0D"/>
      <path d="M57.9902 10.5236V2.61487H58.9612V10.5236H57.9902Z" fill="#0D0D0D"/>
      <path d="M60.1064 7.65872C60.1064 6.59815 60.4015 5.81218 60.9917 5.30082C61.4841 4.87615 62.0848 4.66382 62.7937 4.66382C63.5815 4.66382 64.2253 4.92192 64.7252 5.43812C65.2251 5.95433 65.4751 6.66746 65.4751 7.57753C65.4751 8.31552 65.3651 8.89576 65.145 9.31822C64.9289 9.73553 64.5924 10.0784 64.1793 10.3024C63.7553 10.5371 63.2777 10.6581 62.7931 10.6536C61.9913 10.6536 61.3428 10.3964 60.8477 9.88195C60.3527 9.36751 60.1056 8.62643 60.1064 7.65872ZM61.1052 7.65872C61.1052 8.39276 61.2652 8.94284 61.5851 9.30898C61.7352 9.48142 61.9205 9.61968 62.1285 9.71442C62.3366 9.80916 62.5625 9.85818 62.7911 9.85818C63.0197 9.85818 63.2456 9.80916 63.4537 9.71442C63.6617 9.61968 63.847 9.48142 63.9971 9.30898C64.3175 8.94196 64.4775 8.38264 64.477 7.63099C64.477 6.92204 64.3162 6.38515 63.9945 6.02034C63.8442 5.84863 63.659 5.71102 63.4512 5.61674C63.2435 5.52246 63.0179 5.47368 62.7898 5.47368C62.5616 5.47368 62.3361 5.52246 62.1283 5.61674C61.9205 5.71102 61.7353 5.84863 61.5851 6.02034C61.2652 6.37855 61.1052 6.92468 61.1052 7.65872Z" fill="#0D0D0D"/>
      <path d="M70.3527 9.81727C70.0456 10.0905 69.6942 10.3094 69.3137 10.4648C68.9712 10.5922 68.6084 10.6562 68.243 10.6536C67.6133 10.6536 67.1292 10.4996 66.7908 10.1916C66.6256 10.0437 66.4947 9.86168 66.4071 9.65806C66.3196 9.45444 66.2775 9.23416 66.2838 9.0126C66.2821 8.75154 66.3444 8.49404 66.4652 8.26262C66.586 8.0312 66.7617 7.83292 66.9769 7.68513C67.1907 7.54151 67.4267 7.43402 67.6753 7.36696C67.9597 7.30003 68.2481 7.2513 68.5387 7.22107C69.3229 7.12734 69.9003 7.01578 70.2709 6.8864C70.2748 6.75438 70.2761 6.66857 70.2761 6.63292C70.2761 6.23686 70.1844 5.95807 70.0009 5.79657C69.7527 5.57653 69.3841 5.46651 68.8952 5.46651C68.4384 5.46651 68.1011 5.54661 67.8833 5.70679C67.6654 5.86698 67.5046 6.15038 67.4007 6.55701L66.4508 6.42499C66.5388 6.01924 66.681 5.69117 66.8773 5.44077C67.0735 5.19037 67.3596 4.99784 67.7354 4.86318C68.154 4.72119 68.5939 4.65263 69.0358 4.66052C69.5252 4.66052 69.9228 4.71817 70.2286 4.83347C70.5345 4.94877 70.7591 5.09355 70.9026 5.26782C71.055 5.46031 71.1588 5.68676 71.2049 5.92793C71.2457 6.22467 71.2621 6.52427 71.2538 6.82369V8.11882C71.2538 9.02184 71.2744 9.59284 71.3158 9.83179C71.3575 10.0735 71.4406 10.3062 71.5614 10.5196H70.5468C70.4406 10.299 70.3749 10.0611 70.3527 9.81727ZM70.2715 7.64816C69.9195 7.79207 69.3914 7.91441 68.6873 8.01518C68.2881 8.07283 68.0058 8.13752 67.8404 8.20925C67.6838 8.27371 67.5502 8.38362 67.4568 8.52478C67.3461 8.69803 67.3012 8.90528 67.3304 9.10882C67.3596 9.31235 67.4609 9.49863 67.6159 9.63376C67.8117 9.80583 68.0978 9.89208 68.4741 9.89252C68.8225 9.89898 69.1665 9.81433 69.4721 9.64696C69.7514 9.4962 69.9761 9.26131 70.1144 8.97564C70.2187 8.7556 70.2709 8.43171 70.2709 8.00396L70.2715 7.64816Z" fill="#0D0D0D"/>
      <path d="M76.4762 10.5236V9.80078C76.1131 10.3689 75.5791 10.6532 74.8741 10.6536C74.4257 10.6563 73.9868 10.5245 73.614 10.2754C73.2233 10.0146 72.9145 9.64838 72.7235 9.21923C72.5051 8.72992 72.3973 8.19844 72.4079 7.6627C72.4005 7.13016 72.4976 6.60133 72.6938 6.10617C72.8615 5.66873 73.1615 5.29447 73.5519 5.03548C73.9317 4.78714 74.3768 4.65732 74.8305 4.66252C75.1382 4.65756 75.4425 4.72685 75.7178 4.86451C75.993 5.00217 76.2309 5.20415 76.4115 5.45332V2.61487H77.3772V10.5236H76.4762ZM73.406 7.66402C73.406 8.39806 73.56 8.9466 73.8681 9.30966C74.1761 9.67272 74.5412 9.85447 74.9632 9.85491C75.3883 9.85491 75.749 9.6813 76.0451 9.33409C76.3413 8.98687 76.4898 8.45725 76.4907 7.74521C76.4907 6.96101 76.3395 6.38539 76.0372 6.01837C75.7349 5.65136 75.3626 5.46785 74.9203 5.46785C74.4886 5.46785 74.128 5.64387 73.8384 5.99593C73.5488 6.34799 73.4047 6.90402 73.406 7.66402Z" fill="#0D0D0D"/>
      <path d="M81.6133 7.65872C81.6133 6.59815 81.9081 5.81218 82.4978 5.30082C82.9907 4.87615 83.5914 4.66382 84.2999 4.66382C85.0881 4.66382 85.7321 4.92192 86.232 5.43812C86.732 5.95433 86.9819 6.66746 86.9819 7.57753C86.9819 8.31552 86.8719 8.89576 86.6519 9.31823C86.4357 9.73553 86.0993 10.0784 85.6861 10.3024C85.2621 10.5373 84.7846 10.6582 84.2999 10.6536C83.4981 10.6536 82.8499 10.3964 82.3552 9.88196C81.8606 9.36751 81.6133 8.62643 81.6133 7.65872ZM82.6114 7.65872C82.6114 8.39276 82.7715 8.94285 83.0919 9.30898C83.2419 9.48141 83.4272 9.61965 83.6352 9.71439C83.8432 9.80912 84.069 9.85815 84.2976 9.85815C84.5261 9.85815 84.752 9.80912 84.96 9.71439C85.168 9.61965 85.3533 9.48141 85.5033 9.30898C85.8237 8.94197 85.9838 8.38264 85.9838 7.631C85.9838 6.92204 85.8228 6.38515 85.5006 6.02034C85.3505 5.84864 85.1653 5.71104 84.9576 5.61677C84.7499 5.52249 84.5244 5.47372 84.2963 5.47372C84.0682 5.47372 83.8427 5.52249 83.635 5.61677C83.4273 5.71104 83.2421 5.84864 83.0919 6.02034C82.7702 6.37855 82.6094 6.92468 82.6094 7.65872H82.6114Z" fill="#0D0D0D"/>
      <path d="M88.1211 10.5237V4.79328H88.9951V5.60785C89.4153 4.97811 90.0233 4.66324 90.8189 4.66324C91.1456 4.65955 91.4696 4.7229 91.7708 4.84939C92.0285 4.94913 92.2535 5.11819 92.421 5.33787C92.5709 5.55324 92.6739 5.79769 92.7234 6.0554C92.7594 6.23583 92.7775 6.5507 92.7775 7.00001V10.523H91.8065V7.03764C91.8065 6.64158 91.7686 6.34563 91.6929 6.1498C91.6159 5.95238 91.4742 5.78693 91.2909 5.68046C91.0858 5.56043 90.8513 5.49986 90.6137 5.50553C90.2198 5.49895 89.8377 5.64027 89.543 5.9016C89.2424 6.16564 89.0921 6.66402 89.0921 7.39674V10.5256L88.1211 10.5237Z" fill="#0D0D0D"/>
      <path d="M99.4567 9.65492L99.5973 10.5131C99.3562 10.5669 99.1103 10.5959 98.8633 10.5995C98.5112 10.5995 98.2379 10.5436 98.0434 10.4319C97.8616 10.3348 97.7172 10.1802 97.6328 9.99223C97.5545 9.81092 97.5151 9.42872 97.5147 8.84563V5.54906H96.8018V4.79324H97.514V3.37401L98.4797 2.79114V4.79324H99.456V5.5484H98.4797V8.8991C98.4797 9.17634 98.4969 9.35391 98.5306 9.43312C98.5648 9.51238 98.6236 9.57855 98.6982 9.62191C98.8 9.67437 98.914 9.69875 99.0283 9.69254C99.1718 9.69 99.3149 9.67742 99.4567 9.65492Z" fill="#0D0D0D"/>
      <path d="M100.406 10.5236V2.61487H101.377V5.45332C101.585 5.2011 101.848 4.9992 102.145 4.86271C102.442 4.72623 102.767 4.65869 103.094 4.66516C103.477 4.65584 103.857 4.74228 104.199 4.91666C104.495 5.06571 104.731 5.30985 104.871 5.60977C105.006 5.90506 105.073 6.33302 105.073 6.89367V10.5243H104.102V6.89367C104.102 6.4096 103.997 6.05622 103.787 5.83354C103.576 5.61087 103.279 5.50085 102.894 5.50349C102.608 5.5032 102.327 5.58079 102.082 5.72793C101.839 5.86491 101.648 6.07814 101.539 6.33456C101.432 6.58981 101.378 6.94186 101.377 7.39073V10.5256L100.406 10.5236Z" fill="#0D0D0D"/>
      <path d="M110.475 8.67865L111.478 8.80275C111.32 9.38893 111.027 9.84396 110.599 10.1679C110.171 10.4917 109.625 10.6537 108.959 10.6537C108.12 10.6537 107.456 10.3956 106.965 9.87939C106.473 9.36318 106.228 8.63927 106.229 7.70764C106.229 6.743 106.477 5.99488 106.973 5.46328C107.47 4.93167 108.113 4.66543 108.905 4.66455C109.671 4.66455 110.297 4.92551 110.782 5.44744C111.267 5.96936 111.509 6.70318 111.508 7.64889C111.508 7.70632 111.508 7.79279 111.503 7.90765H107.231C107.267 8.53739 107.445 9.01949 107.765 9.35394C107.918 9.51707 108.104 9.64607 108.311 9.73251C108.517 9.81896 108.74 9.8609 108.963 9.85562C109.29 9.86473 109.61 9.76614 109.875 9.57508C110.128 9.38717 110.328 9.08836 110.475 8.67865ZM107.286 7.10826H110.486C110.442 6.62639 110.319 6.26487 110.119 6.02371C109.975 5.84351 109.792 5.69906 109.583 5.6017C109.374 5.50434 109.145 5.45673 108.915 5.46262C108.708 5.45789 108.501 5.49515 108.308 5.57217C108.115 5.64919 107.94 5.76438 107.793 5.91083C107.487 6.2092 107.318 6.60834 107.286 7.10826Z" fill="#0D0D0D"/>
    </svg>
  </button>
</template>

<script>
  export default {
    emits: ['action'],

  }
</script>