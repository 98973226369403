<template>
  <div class="item-0-1" id="about-main">
    <div class="about-item">
      <h1>About Us</h1>
    </div>
    
    <div class="about-item item-2">
      <h2>
        Appio Games stands as inventive mobile apps studio focused on transforming ideas into standout apps.
      </h2>
      <p>
        As a rapidly expanding company, we’re constantly involved in thrilling projects while utilizing cutting-edge technologies and equipment.
        We’re more than just developers; we’re your digital world partners.
      </p>
    </div>
  </div>
</template>

<style lang="scss">
  #about-main {
    padding: 40px 16px;
  }

  .about-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: black;
    h1 {
      font: 800 28px/30.8px Avenir, sans-serif;
    }
    h2 {
      font: 500 18px/25.2px Avenir, sans-serif;
    }
    p {
      font: 500 14px/21px Avenir, sans-serif;
    }
  }
  .item-0-1 {
    padding: 0px 0px 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    box-sizing: border-box;
  }
  .about-item.item-2 {
    gap: 15;
  }

  @media (min-width: 735px) {
    .about-item {
      >h1 {
        font: 800 40px/44px Avenir, sans-serif;
      }
    }
    .about-item.item-2 {
      gap: 20px;
      >h2 {
        font: 500 32px/44.8px Avenir, sans-serif;
      }
      >p {
        font: 500 20px/30px Avenir, sans-serif;
      }
    }
  }
</style>