<template>
  <div class="modal-layout">
    <div class="modal-main">
      <div class="modal-item item-1">
        <h1>Thank you!</h1>
        <p>
          Thank you for your message, it was sent successfully
        </p>
      </div>

      <!-- <ButtonApp @action="$emit('showModalStatus')" :btnText="'Close'"/> -->
      <div class="button" @click="$emit('showModalStatus')">Close</div>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script>
  export default {
    emits: ['showModalStatus'],
  }
</script>

<style lang="scss">
  .modal-layout {
    width: 100%;
    height: 100%;
    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    top: 0;
    left: auto;
    z-index: 1;

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: auto;
      left: auto;
      background: rgba($color: #000000, $alpha: 0.6);
    }

    .modal-main {
      width: 60%;
      padding: 80px 50px;

      display: flex;
      flex-direction: column;
      gap: 60px;

      border-radius: 20px;
      background: #9D98FE;
      z-index: 2;
      .item-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        z-index: 3;

        >h1 {
          font: 800 40px/44px Avenir, sans-serif;
        }
        >p {
          font: 500 20px/28px Avenir, sans-serif;
        }
      }
    }
    .button {
      cursor: pointer;
      padding: 16px 12px;
      color: black;
      font: 800 20px/28px Avenir, sans-serif;
      background: #DDFE56;
      border: none;
      border-radius: 50px;
    }
  }
</style>