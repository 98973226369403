<template>
    <!-- Hamburger icon -->
    <input ref="side" class="side-menu" type="checkbox" id="side-menu"/>
    <label class="hamb" for="side-menu"><span class="hamb-line"></span></label>
    <!-- Menu -->
    <nav class="nav" id="nav">
      <ul class="menu">
        <li v-for="item in nav_data" :key="item.id">
          <a class="golos-400" :ref="`linkItem${item.id}`" @click="goToElem(item.link)">{{ item.text }}</a>
        </li>
      </ul>
    </nav>

</template>

<script>
  import { nav_data } from '@/db';
  import smoothscroll from 'smoothscroll-polyfill';
 
  smoothscroll.polyfill();

  export default {
    data() {
      return {
        nav_data
      }
    },
    methods: {
      goToElem(link) {
        let element = document.getElementById(link);

        element.scrollIntoView({behavior: 'smooth'});
        if (this.$refs.side.checked) {
          this.$refs.side.checked = false;
        }

      }
    }
  }
</script>

<style lang="scss">
  a {
    text-decoration: none;
    cursor: pointer;
  }
  ul {
    list-style: none;
  }

  $white: #f9f9f9;
  $gray: #85888C;
  $black: #36383F;

@media (min-width: 735px) {
    #nav{
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      background-color: rgba($color: #000000, $alpha: 0.0);
      height: 50%;
      padding: 0;
      width: 50%;
      margin-left: auto;
      > .menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        a {
          display: inline;
          padding: 0;
        }
        li {
          width: auto;
        }
      }
      .menu a:hover{
        background-color: rgba($color: #000000, $alpha: 0.0);
      }
    }
    .menu li{
      float: left;
    }
    .menu a:hover{
      // background-color: transparent;
      color: $gray;
    }
 
    .hamb{
      display: none;
    }
}

@media (min-width: 735px) and (max-width: 1000px) {
  #nav { 
    width: 70%;
  }
}

.navi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.golos-400 {
  font: 500 18px/25.2px Avenir, sans-serif;
}

.nav{
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $black;
  overflow: hidden;
  z-index: 20;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 40px;
  z-index: 20;
  padding: 0;
  width: 100%;
  > li {
    width: 100%;
  }
}
.menu a{
    display: block;
    width: 100%;
    padding: 30px;
    color: $white;
    box-sizing: border-box;
}
.menu a:hover{
  background-color: $gray;
}
.nav{
  width: 0;
  background: #0D0D0D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: width .5s ease-out;
}

.hamb{
    cursor: pointer;
    float: right;
    padding: 40px 20px;
    z-index: 30;
    position: fixed;
    right: 0;
}/* Style label tag */
 
.hamb-line {
    background: $white;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
 
} /* Style span tag */
 
.hamb-line::before,
.hamb-line::after{
    background: $white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
.hamb-line::before{
    top: 5px;
}
.hamb-line::after{
    top: -5px;
}
 
.side-menu {
    display: none;
} /* Hide checkbox */


.side-menu:checked ~ nav{
  width: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top:0;
}
</style>