<script setup>
import { contact_data } from "@/db";
import ButtonApp from "./ButtonApp.vue";
import axios from "axios";
import { ref } from "vue";
import StatusSendFormModal from "./StatusSendFormModal.vue";

const name = ref("");
let nameElement = ref(null);
const email = ref("");
let emailElement = ref(null);
const message = ref("");
let errorMessage1 = ref(null);
let errorMessage2 = ref(null);
let actionBtn = ref(null);
const regExpEmail = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z_-]+)$/;

let showModal = ref(false);

function showErrorName() {
  if (!nameElement.value.value) {
    errorMessage1.value.textContent = 'You need to enter an name';
    nameElement.value.classList.add('inp-error');
    return 0;
  } else {
    errorMessage1.value.textContent = '';
    nameElement.value.classList.remove('inp-error');
  }
}

function showErrorEmail() {
  if (!emailElement.value.value) {
    errorMessage2.value.textContent = 'You need to enter an e-mail address.';
    emailElement.value.classList.add('inp-error');
    return 0;
  } else if (!regExpEmail.test(emailElement.value.value)) {
    errorMessage2.value.textContent = 'Entered value needs to be an e-mail address.';
    emailElement.value.classList.add('inp-error');
    return 0;
  } else {
    errorMessage2.value.textContent = '';
    emailElement.value.classList.remove('inp-error');
  }
}

function showModalStatus() {
  showModal.value = !showModal.value;
}

async function sendEmail() {

  showErrorName();
  showErrorEmail();

  if (showErrorEmail() == 0 || showErrorName() == 0) {
    return 0;
  }

  try {
    const payload = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    await axios.post(
      "https://webform.dkotrack.com/api/new/appiogames.com",
      payload
    );
  } catch (error) {
    console.error(error?.response?.data || error?.message || error);
  } finally {
    console.info("end send mail");
    showModalStatus();
  }
}

</script>
<template>
  <form @submit.prevent="sendEmail">
    <StatusSendFormModal @showModalStatus="showModalStatus" v-if="showModal"/>
    <div class="block-form-1">
      <h1>Get in touch</h1>
      <div class="inp-group">
        <span ref="errorMessage1" class="error-main"></span>
        <input ref="nameElement" type="text" placeholder="Your name" class="name-element" v-model="name" @blur="showErrorName()"/>
      </div>
    </div>

    <div class="block-form-2">
      <div id="block-inp">
        <div class="inp-group">
          <span ref="errorMessage2" class="error-main"></span>
          <input ref="emailElement" type="text" placeholder="Your email" class="email-element" v-model="email" @blur="showErrorEmail()"/>
        </div>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Your message"
          v-model="message"
        ></textarea>
        <ButtonApp ref="actionBtn" :btnText="'Send Message'" />
      </div>
      <div class="contact-block">
        <div class="contact-item" v-for="item in contact_data" :key="item.id">
          <div>
            <img :src="item.icon" />
          </div>
          <p :class="`it-${item.id}`">{{ item.text }}</p>
        </div>
      </div>
    </div>
  </form>
</template>

<style lang="scss">
@import "@/style/mixins.scss";

@media (min-width: 720px) {
  .block-form-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > * {
      width: 50%;
    }
    >.inp-group {
      width: 50%;
    }
    > h1 {
      width: 48%;
      text-align: left;
    }
  }
  .block-form-2 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    #block-inp {
      width: 50%;
    }
    > div {
      width: 50%;
    }

    > div:nth-child(2) {
      width: 30%;
    }
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  > h1 {
    align-self: flex-start;
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    > input,
    > textarea {
      @include base-input;
    }
    > input::placeholder {
      color: black;
      font: 500 14px/19.6px Avenir, sans-serif;
    }
    > div:nth-child(1) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      > textarea {
        @include base-input;
      }
      > textarea::placeholder {
        color: black;
        font: 500 14px/19.6px Avenir, sans-serif;
      }
    }
  }
}

.email-element, .name-element {
  @include base-input;
}
.email-element::placeholder, .name-element::placeholder {
  color: black;
  font: 500 14px/19.6px Avenir, sans-serif;
}

.contact-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    > div {
      background: black;
      width: 1rem;
      height: 1rem;
      padding: 6.12px;
      border-radius: 35px;
    }
    > p {
      box-sizing: border-box;
      text-align: left;
      font: 500 16px/22.4px Avenir, sans-serif;
    }
  }
}

.inp-group {
  width: 100%;
}
.inp-error {
  border-color: red;
}
.inp-error::placeholder {
  color: red;
}
.inp-group {
  position: relative;
}
.error-main {
  position: absolute;
  color: red;
  top: -10px;
}
</style>
