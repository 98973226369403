<template>
  <div class="team-display-1">
    <h1 class="team-h1">Join Our Team</h1>
    <h2 class="team-h2">
      Appio Games is a rapidly expanding company with a passion for creating projects designed to assist users in their daily lives. We are currently seeking professional collaborators to join our dynamic team.
    </h2>
  </div>

  <div class="team-display-2">
    
    <div class="team-block">
      <div class="team-item" v-for="item in team_data" :key="item.id">
        <h1>{{ item.h }}</h1>
        <p>{{ item.p }}</p>
      </div>
    </div>
    
    <img src="@/assets/vacancies/icon-1.png">
  </div>
</template>

<script>
  export default {
    data() {
      return {
        team_data: [
          {
            id: 1,
            h: 'GAME DESIGNER',
            p: 'We are actively seeking a creative and experienced Game Designer to join our team at Appio Games.',
          },
          {
            id: 2,
            h: 'BACKEND DEVELOPER',
            p: 'We are currently in search of a skilled Backend Developer who can integrate into existing development processes. Feel free to reach out to us if you’re interested.',
          },
          {
            id: 3,
            h: 'UNITY DEVELOPER',
            p: 'We are on the lookout for a proficient Unity Developer to join us. Please, contact us!',
          },
        ]
      }
    }
  }
</script>

<style lang="scss">

  @media (min-width: 710px) {
    .team-h1 {
      align-self: flex-start;
    }
    .team-display-1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      // justify-content: space-between;
      >h2 {
        // width: 50%;
        text-align: left;
      }
    }
    .team-display-2 {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      >.team-block {
        width: 50%;
        >.team-h2 {
          padding-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 700px) {

    .team-display {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .team-display-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      gap: 30px;
    }
  }
  .team-h1 {
    font: 800 28px/30.8px Avenir, sans-serif;
  }
  .team-h2 {
    font: 500 18px/25.2px Avenir, sans-serif;
  }
  .team-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // margin: 5px 0px;
    padding: 10px 0px;
    text-align: left;
    border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.2);
    >h1 {
      font: 800 16px/22.4px Avenir, sans-serif;
    }
    >p {
      font: 500 14px/19.6px Avenir, sans-serif;
    }
  }
  .team-display-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
</style>
