<template>
  <div class="footer-block" id="footer-block">
    <img src="@/assets/logo.svg">
    <!-- <NavigationApp class="test" id="test" /> -->
  </div>

  <p class="footer-p">© 2023 Appio Oyun Yazilim Ve Reklam Anonim Sirketi</p>
</template>

<script>
// import NavigationApp from './NavigationApp.vue';

  export default {
    components: {
    // NavigationApp
}
  }
</script>

<style lang="scss">

  @media(min-width: 720px) {
    #footer-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      >#test {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 50%;
        gap: 20px;
        >a {
          width: (100% / 3);
          text-align: left;
        }
      }
    }
    .footer-p {
      margin-right: auto;
    }
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    >.test {
      display: flex;
      flex-direction: column;
      flex-flow: wrap;
      gap: 20px;
      width: 100%;
      a {
        width: (100% / 3);
        text-align: left;
      }
    }
  }
  .footer-p {
    color: rgba($color: #FFFFFF, $alpha: 0.8);
    font: 500 12px/16.8px Avenir, sans-serif;
  }
</style>