<template>
  <div class="features-header">
    <h1>We Provide The Best Features</h1>
  </div>

  <div class="block-feat">
    <div v-for="item in features_data" :key="item.id">
      <img :src="item.icon">
      <p>
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script>
  import { features_data } from '@/db';

  export default {
    data() {
      return {
        features_data,
      }
    }
  }
</script>

<style lang="scss">
  .features-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    >h1 {
      width: 70%;
      font: 800 28px/30.8px Avenir, sans-serif;
    }
  }

  .block-feat {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    >div {
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      border-radius: 20px;
      background: #1D1D1D;
      width: 100%;
      box-sizing: border-box;
    }
  }
</style>