import itemBrainy from '@/assets/projects/Mockup.png';
import itemAiChatBot from '@/assets/projects/project-2-2.png';
import itemLocate from '@/assets/projects/Mockup-3.png';

import iconBrainy from '@/assets/brainTrain-icon.png';
import iconAiChatBot from '@/assets/projects/Icon-1.svg';
import iconLocate from '@/assets/projects/Icon.svg';

import iconFeature1 from '@/assets/features/Icon_1.svg';
import iconFeature2 from '@/assets/features/Icon_2.svg';
import iconFeature3 from '@/assets/features/Icon_3.svg';
import iconFeature4 from '@/assets/features/Icon_4.svg';
import iconFeature5 from '@/assets/features/Icon_5.svg';
import iconFeature6 from '@/assets/features/Icon_6.svg';

import iconContact_1 from '@/assets/contact/icon-1.svg';
import iconContact_2 from '@/assets/contact/icon-2.svg';
import iconContact_3 from '@/assets/contact/icon-3.svg';

export const nav_data = [
  {
    id: 1,
    link: 'about-main',
    text: 'About',
  },
  {
    id: 2,
    link: 'app-main-projects',
    text: 'Projects',
  },
  {
    id: 3,
    link: 'app-main-features',
    text: 'Features',
  },
  {
    id: 4,
    link: 'app-main-vacancies',
    text: 'Join us',
  },
  {
    id: 5,
    link: 'app-main-contact',
    text: 'Contact us',
  }
]

export const project_data = [
  {
    id: 1,
    app_image: itemBrainy,
      // {
      //   id: 1,
      //   item: itemBrainy1,
      // },
      // {
      //   id: 2,
      //   item: itemBrainy2,
      // }
    app_icon: iconBrainy,
    app_name: 'Brainy Train',
    app_description: "Brainy Train is designed to enhance cognitive functions like memory, math skills, attention, logic, and problem-solving. By engaging in tests, riddles, and brain teasers, you can alleviate daily stress. The app incorporates a unique workout schedule to maintain mental acuity throughout your gaming experience. Varied games with consistent training establish robust productive patterns and refine mental capabilities, significantly improving your day-to-day life.",
    app_url: 'https://apps.apple.com/ru/app/brainy-train-logic-brain-game/id6443554379',
  },
  {
    id: 2,
    app_image: itemAiChatBot,
      // {
      //   id: 1,
      //   item: itemAiChatBot1,
      // },
      // {
      //   id: 2,
      //   item: itemAiChatBot2,
      // }
    app_icon: iconAiChatBot,
    app_name: 'AI Chat Bot',
    app_description: "This application utilizes genuine artificial intelligence technology to address a wide range of inquiries, from basic questions to intricate ones. Can’t find an answer? This app is your round-the-clock assistant. What sets this app apart is its extensive knowledge base acquired from numerous books, articles, and websites, making it exceptionally adept at language comprehension and providing precise responses. Plus, its intelligence grows with each interaction, constantly improving its service.",
    app_url: 'https://apps.apple.com/ru/app/ai-chat-bot-virtual-helper/id6449024742',
  },
  {
    id: 3,
    app_image: itemLocate,
      // {
      //   id: 1,
      //   item: itemLocate,
      // },
    app_icon: iconLocate,
    app_name: 'Number Tracker',
    app_description: 'Our location tracker app offers real-time tracking, allowing you to stay connected with your loved ones at all times. With its user-friendly interface and precise location updates, you can ensure the safety and whereabouts of your family and friends. Whether they’re across town or across the globe, keep peace of mind knowing you can locate them with a tap of a button.',
    app_url: 'https://apps.apple.com/ru/app/number-tracker-find-my-phone/id1668035109',
  },
]

export const features_data = [
  {
    id: 1,
    icon: iconFeature1,
    text: 'Exciting Game Mechanics',
  },
    {
    id: 2,
    icon: iconFeature2,
    text: 'Cross-Platform Compatibility',
  },
  {
    id: 3,
    icon: iconFeature3,
    text: 'User-Friendly Interface',
  },
  {
    id: 4,
    icon: iconFeature4,
    text: 'High-Quality Graphics',
  },
  {
    id: 5,
    icon: iconFeature5,
    text: 'Regular Updates',
  },
  {
    id: 6,
    icon: iconFeature6,
    text: 'Secure Code',
  },
]

export const contact_data = [
  {
    id: 1,
    icon: iconContact_1,
    text: '+905305610546',
  },
  {
    id: 2,
    icon: iconContact_2,
    text: 'ceo@appiogames.com',
  },
  {
    id: 3,
    icon: iconContact_3,
    text: '34485 Turkiye, ISTANBUL, Maslak mah. Maslak Meydan Sk. Beybi Giz Plaza A Blok, No 1/55 Sariyer',
  },
]