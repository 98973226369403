<template>
  <header id="app-header">
    <HeaderApp />
    <AboutUs />
    <img id="back-1" src="@/assets/app-back-header-1.svg">
    <img id="back-2" src="@/assets/app-back-header-2.svg">
  </header>
  
  
  <main id="app-main">
    <div id="app-main-projects">
      <ProjectsApp />
    </div>
    <div id="app-main-features">
      <BestFeatures />
    </div>
    <div id="app-main-vacancies">
      <OurTeam />
    </div>

  </main>
  <div id="app-main-contact">
    <FormTouch />
  </div>
  <footer id="app-footer">
    <FooterApp />
  </footer>
  <!-- <router-view/> -->
</template>

<script>
  import HeaderApp from './components/HeaderApp.vue';
  import AboutUs from './components/AboutUs.vue';
  import ProjectsApp from './components/ProjectsApp.vue';
  import BestFeatures from './components/BestFeatures.vue';
  import OurTeam from './components/OurTeam.vue';
  import FormTouch from './components/FormTouch.vue';
  import FooterApp from './components/FooterApp.vue';

  export default {
    components: {
      HeaderApp,
      AboutUs,
      ProjectsApp,
      BestFeatures,
      OurTeam,
      FormTouch,
      FooterApp,
    }
  }
</script>

<style lang="scss">
  @import '@/style/mixins.scss';

#app {
  font-family: Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #9D98FE;
  // padding: 0px 16px;
  >div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}

#app-header {
  padding: 33px 0px 0px 0px;
  @include flex-base-column;
  gap: 10rem;
  color: white;
  background: #9D98FE;
  position: relative;
  >img {
    position: absolute;
    // width: 80%;
  }
  #back-1 {
    left: 0;
    top: 0;
  }
  #back-2 {
    right: 0;
    bottom: 15rem;
  }
}
#app-main {
  @include flex-base-column;
  gap: 40px;
  background: black;
  color: white;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  >div {
    // padding: 40px 16px;
    @include flex-base-column;
    width: 100%;
    gap: 30px;
    box-sizing: border-box;
  }

  #app-main-contact {
    background: #9D98FE;
    border-radius: 10px;
    color: black;
  }
}

#app-footer {
  padding: 30px 16px;
  background: black;
  color: white;
  gap: 80px;
}

</style>
