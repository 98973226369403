<template>
  <div style="align-self: flex-start;">
    <h1>Projects</h1>
  </div>

  <div id="projects-main">
    <div v-for="item in project_data" :key="item.id" :class="`project-item item-${item.id}`">
      <div>
        <img class="app-image" :src="item.app_image">
      </div>
      <div>
        <img :src="item.app_icon" style="width: 20%; border-radius: 10px;">
        <h1 class="test-h1">{{ item.app_name }}</h1>
        <p class="test-p" style="text-align: left;">{{ item.app_description }}</p>
        <ButtonApple @action="goToApp(item.app_url)" />
      </div>
    </div>
  </div>
</template>

<script>
import { project_data } from '@/db';
import ButtonApple from './ButtonApple.vue';

export default {
    data() {
        return {
            project_data
        };
    },
    components: { ButtonApple },
    methods: {
      goToApp(url) {
        window.open(url);
      }
    }
}
</script>

<style lang="scss">
  #projects-main {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    >div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
  .test-h1 {
    font: 800 32px/44.8px Avenir, sans-serif;
  }
  .test-p {
    font: 500 20px/28px Avenir, sans-serif;
  }
</style>